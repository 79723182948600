//blog-treding
.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

//modals
.iq-modal {
  > .modal-dialog {
    max-width: 1400px;
  }
}

//file-manager dropzone
.file-manager-modal {
  .dzu-dropzone {
    min-height: 500px;
    overflow: hidden;
    border: 1px solid #8a92a6;
  }
}
.dark {
  .file-manager-modal {
    .dzu-dropzone {
      background-color: #151824;
    }
  }
}
.btn-upload {
  input[type='file']::file-selector-button {
    background-color: var(--bs-primary);
    border-radius: 0.25rem;
    box-shadow: none;
    color: white;
    border: 1px solid var(--bs-primary);
  }
  input[type='file']::file-selector-button:hover {
    background-color: rgba(var(--bs-primary), 0.9);
  }
}

//dark choices

.dark .choices[data-type*='select-one'] {
  .choices__input {
    background: #222738;
    border-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.dark .is-open .choices__list[aria-expanded] {
  border-color: rgba(#ffff, 0.1);
}

.custom-dropdown-focus:focus,
.custom-dropdown-focus:focus:not(:focus-visible) {
  color: white !important;
}

.custom-button-focus .btn:focus {
  color: white !important
}

.custom-dropdown-focus[aria-expanded="true"] {
  color: white !important;
}

.horizontal-line {
  height: 1px;
  width: 100%;
  background-color: #eeeeee;
}

.horizontal-line-negative-margin {
  height: 1px;
  background-color: #eeeeee;
  margin-left: -13px;
  margin-right: -13px;
}

// skeletion loader styles
.skeleton-loader {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: loading 1.2s infinite ease-in-out;
}

.skeleton-image {
  width: 100%;
}

.skeleton-title {
  width: 70%;
}

@keyframes loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f5f5f5;
  }
  100% {
    background-color: #e0e0e0;
  }
}

.dropdown-no-arrow::after {
  display: none;
}

.round-button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.round-modal-close-button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

//Accordion
.accordion-button:not(.collapsed) {
  background-color: var(--bs-accordion-bg);
  color: black;
}

.nested-accordion .accordion-button:not(.collapsed),
.nested-accordion .accordion-item .accordion-body {
  background-color: #fff;
}

.nested-accordion .accordion-item {
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.long-text-column {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.long-text-column a {
  display: block;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.selected-row {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
}

h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000;
}

.fa-circle,
.fa-square,
.fa-chevron-up,
.fa-chevron-down,
.fa-trash-can {
  cursor: pointer !important;
}

.fa-grip-vertical {
  cursor: grab !important;
}

.no-arrow {
  border-radius: var(--bs-accordion-inner-border-radius);
  background-color: var(--bs-accordion-btn-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
  font-weight: 400;
  line-height: 1.2;
}
.no-arrow::after {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

.bg-light-blue {
  background-color: #ebeefd;
}

.bg-grey {
  background-color: #f9f9f9 !important
}

.bg-dark-grey {
  background-color: #d4d4d4c9 !important;
}

.generic-info-card-image {
  max-height: 200px;
  width: auto !important;
  margin: 0 auto;
}

.media-picker-preview-container {
  height: 60px;
  width: 100px;
  overflow: hidden;
}

.custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1600px !important;
  }
}

.bg-badge-gray {
  background-color: #f9f9f9 !important;
  color: #8a92a6 !important;
  font-size: small;
  font-weight: 400;
}

.dropdown-menu {
  z-index: 1054123 !important;
}

.media-picker-preview-image {
  width: 80px;
  max-height: 80px;
  margin-right: 15px;
}

.media-picker-preview-wrapper {
  min-height: 100px;
  padding: 10px;
}

.list-no-dots {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.table-dropzone-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropzone-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.dropzone-overlay > div {
  pointer-events: all;
}

.no-border-dropdown .dropdown-toggle {
  border: none !important;
}

.no-border-dropdown .dropdown-menu {
  border: none !important;
}

.btn-muted {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
}
.btn-muted:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-muted:disabled {
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f0f0f0;
  color: #000;
}

.dropdown-menu .dropdown-item:focus {
  background-color: #f0f0f0;
  color: #000;
}

.search-input .dropdown-toggle {
  background: #f9f9f9 !important;
  color: black !important;
  border: 1px solid #eee !important;
}

.search-input .dropdown-toggle::after {
  margin-left: 0.5rem;
}

.custom-search-input {
  background: #f9f9f9 !important;
  color: black;
  border: 1px solid #eee;
}

.custom-search-input:focus {
  background: #f9f9f9 !important;
  color: black;
  border: 1px solid #eee;
}

.custom-search-input::-webkit-search-cancel-button {
  cursor: pointer;
}

.center-image {
  height: 100%;
  width: auto !important;
  margin: 0 auto;
}

.no-border {
  border: none;
}

.list-group-item.active {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  border-color: rgba(var(--bs-primary-rgb), 0.1);
  color: var(--bs-primary);
}

.sidebar-base .nav-item .nav-link.static-item {
  margin-bottom: 0 !important;
}

.iq-main-menu {
  margin-top: 1rem !important;
}

.disabled-item {
  opacity: 0.5 !important;
  pointer-events: none;
}

.custom-switch .form-check-input {
  cursor: pointer;
}

.custom-switch label {
  cursor: pointer;
}

.sticky-item-text {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.button-group-custom {
  margin-bottom: 10px;
}

.button-group-custom .btn-primary {
  background-color: #e7f3ff;
  color: #0056b3;
  border: none;
}

.button-group-custom .btn-light {
  background-color: #f8f9fa;
  color: #6c757d;
  border: none;
}

.button-group-custom .btn-link {
  color: #007bff;
  text-decoration: none;
}

.dropdown-toggle-no-hover {
  background: none !important;
}

.dropdown-toggle-no-hover:hover {
  background: none !important;
}
.dropdown-toggle-no-hover:focus {
  background: none !important;
}
.dropdown-toggle-no-hover:focus-visible {
  background: none !important;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}
.apexcharts-datalabel-value {
  font-size: 21px;
  fill: #8a92a6;
}

.dashboard-dropdown-button .dropdown button {
  background-color: transparent !important;
  border-color: rgba(0, 0, 0, 0.125) !important;
  color: rgba(0, 0, 0, 0.375) !important;
}

.custom-scrollbar {
  max-height: 300px;
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.no-caret::after {
  display: none !important;
}

.vertical-line {
  border-left: 1px solid #ccc;
  height: 20px;
  margin: 10px;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1000;
}

.overflow-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
}

.custom-scrollbar-x {
  overflow-x: auto;
  padding: 2px;
}

.custom-scrollbar-x::-webkit-scrollbar {
  height: 2px;
}

.custom-scrollbar-x::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 4px;
}

.custom-scrollbar-x::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.fs-13 {
  font-size: 13px !important;
}

.checkbox-pointer input {
  cursor: pointer !important;
}

.checkbox-pointer label {
  cursor: pointer !important;
}

.sticky-left {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1000;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1000;
}

.custom-width-250 {
  width: 250px;
}

.custom-font-size-08 {
  font-size: 0.8rem;
}

.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
}

.sticky-right {
  position: sticky;
  right: 0;
  background-color: white; 
  z-index: 5;
  overflow: visible; 
}

.sticky-right::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #eee;
  z-index: 10;
}

.dropdown-menu-max-height {
  max-height: 200px;
}

.default-input-border {
  border: 1px solid #eee !important;
}

.pac-container {
  z-index: 2000 !important
}

.icon-position-address-autocomplete {
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: black;
  z-index: 2;
  position: absolute;
}

.edit-button {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

td:hover .edit-button {
  visibility: visible;
  opacity: 1;
}

.min-width-editable-table-cell {
  min-width: 200px;
}

.save-cancel-popup {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 1);
  z-index: 1000;
}

.tooltip-overlay-quick-edit {
    padding: 1rem;
    border-radius: 0.375rem;
    position: absolute;
    z-index: 1000;
    width: 300px;
}

.tooltip-arrow-quick-edit {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #dee2e6;
    top: -12px;
    left: 20px;
}

.pending-change {
  color: orange !important;
}

.textarea-no-resize {
  resize: none;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.3) !important
}

textarea::placeholder {
  color: rgba(0, 0, 0, 0.3) !important
}

.editable-table-cell-placeholder:hover {
  background: rgba(0, 0, 0, 0.1) !important
}

.table-cell-wrap {
  max-width: 400px;
  overflow: hidden;
  white-space: 'nowrap';
  text-overflow: ellipsis;
}

.word-break {
  word-break: break-word;
  white-space: normal;
}

.sticky-col {
  position: sticky;
  left: 0;
  background-color: #fff !important;
  z-index: 5;
}

.sticky-col::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #eee;
  z-index: 10;
}

th.sticky-col {
  z-index: 5;
  background-color: #f9f9f9 !important;
}

.sticky-right-reset-filter {
  position: sticky;
  right: 0;
  background-color: white; 
  z-index: 1000
}

.max-w-table-title {
  max-width: 500px !important
}

.solid-simple-border {
  border: 1px solid #eee !important;
}

.max-w-100 {
  max-width: 100% !important
}

.w-40-px {
  width: 40px !important;
}

.location-header-image {
  max-height: 70px;
  width: auto !important;
  margin: 0 auto;
}

.participant-initials {
  width: 100px;
  height: 100px;
  background-color: #3b57f0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.participant-initials-text {
  color: white;
  font-size: 40px;
}

.smiley-icon-container {
  position: absolute;
  top: 90%;
  left: 90%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  width: 30%;
  height: 30%;
  padding: 30%;
}

.comment-icon-container {
  border-radius: 50%;
  padding-left: 5px;
  padding-right: 3px;
  background-color: #8A92A6;
}

.scrollable-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.media-item {
  transition: background-color 0.2s ease-in-out;
}

.media-item:hover {
  background-color: #f1f1f1;
}

.hover-button {
  display: none;
  cursor: pointer;
}

.media-item:hover .hover-button {
  display: inline-block;
}

.switch-option {
  flex: 1;
  color: #6c757d;
  opacity: 0.5;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.switch-option.active {
  color: black;
  opacity: 1
}

.switch-option:not(.active){
  background: none !important
}

.resize-none {
  resize: none !important
}

.border-radius-comment {
  border-radius: 25px;
}